import React from "react";
import Banner from "/src/media/successBanner.jpg";
import Thumbnail from "/src/media/help-item.png";
// import HomePageFilter from "/src/components/AppHeader/components/HomePageFilter";
import { Link } from "react-router-dom";
import APP_CONFIG from "../../config";
import { useTranslation } from "react-i18next";

const AppMobMenuLinks = [
  {
    title: "Придбати",
    route: "/",
  },
  {
    title: "Продати",
    route: "/",
  },
  // {
  //   title: "Оцінити",
  //   route: "/",
  // },
  // {
  //   title: "Про компанію",
  //   route: "/",
  // },
  // {
  //   title: "Новини",
  //   route: "/",
  // },
  {
    title: "Контакти",
    route: "/",
  },
];

const PrimaryCarousel = ({
  successMessage = "Ми зв'яжемося з вами найближчим часом",
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="primary-carousel--wrap">
      <div className="primary-carousel--item primary-carousel--item-success">
        <img src={Banner} alt="Dominanta" />
      </div>

      <div className="primary-carousel-top--layer">
        <div className="container">
          <div className="text-row">
            <div className="cell">
              <h1>{t("Дякуємо")} </h1>
              <p>{t(successMessage)} </p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>

            <div className="cell">
              <div
                className="help-item"
                onClick={() => {
                  window.open(APP_CONFIG.outerLinks.exclusive);
                }}
              >
                <div className="text-layer">
                  <div className="label-text">{t("Допомога експерта")}</div>

                  <h3>{t("Отримайте п'ять пропозицій під ваш запит")}</h3>
                </div>

                <div className="thumbnail-wrap">
                  <img src={Thumbnail} alt={"help"} />
                </div>
              </div>
            </div>
          </div>

          {/* Mobile menu elements */}
          <div className="mobile-menu-scroll--container">
            <ul className="mobile-menu-scroll">
              {AppMobMenuLinks &&
                AppMobMenuLinks.map((obj, i) => {
                  return (
                    <li
                      className="mobile-menu-scroll--item"
                      key={`${i}_mobile-menu-scroll--item`}
                    >
                      <Link className="mobile-menu-link" to={obj.route} key={i}>
                        {t(obj.title)}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="mobile-menu-scroll--container container">
            <div className="container">
              <div className="cell-success ">
                <h1>{t("Дякуємо")} </h1>
                <p>{t(successMessage)} </p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          {/* End */}
          {/*<div className="filter-wrap">*/}
          {/*    <div className="filter-head">*/}
          {/*        <div className="text-row">*/}
          {/*        <div className="cell">*/}
          {/*            <h1>{t("Дякуемо")} </h1>*/}
          {/*            <p>{t("Ми зв'яжемося з вами найближчим часом")} </p>*/}
          {/*            <p>&nbsp;</p>*/}
          {/*            <p>&nbsp;</p>*/}
          {/*            <p>&nbsp;</p>*/}
          {/*            <p>&nbsp;</p>*/}
          {/*        </div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</div>*/}

          {/*<HomePageFilter />*/}
        </div>
      </div>
    </div>
  );
};

export default PrimaryCarousel;
