import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

const BrandBlock = () => {
  const { t, i18n } = useTranslation();


  return (
      <div className="home-page--about home-page--about--brand">
        <div className="home-page--about-bars">
          <div className="bars-row">
          <div className="sell-page-coll">
              <div className="mask-about-us">
              <div className="container">
                <h2>{t("Агентство нерухомості Домінанта працює дванадцять років на одеському ринку")}</h2>
                <p>
                  {t(
                      "11 філій по всьому місту, професійні співробітники, які в найкоротші терміни підберуть Вам найкраще вирішення квартирного питання"
                  )}
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default BrandBlock;
