import React from "react";
import { useTranslation } from "react-i18next";
import images from '../../media/about-us/images';

const OurReviews = () => {
    const { t } = useTranslation();

    return (
        <div className="reviews-page">
            <div className="about-us-page-reviews">
                <div className="about-us-page-reviews--why-we">
                    <div className="container">
                        <div className="about-us-page-reviews--title-why-we">
                            <h2>
                                {t(
                                    "З компанією співпрацюють понад 300 експертів у сфері нерухомості, які безперервно разом з Dominanta, проходять навчання для надання якісних послуг, а після угоди - клієнти оцінюють їхню роботу"
                                )}
                            </h2>
                        </div>
                        <div className="review-cards-container">
                            <div className="review-card-1">
                                <div className="review-header">
                                    <div className="review-author">{t("Руслан Штарк")}</div>
                                    <div className="review-rating">★★★★★</div>
                                </div>
                                <div className="review-date">{t("13 червня 2024")}</div>
                                <div className="review-text">
                                    {t(
                                        "Все було професійно і якісно. Вона зробила все швидко і професійно. Була завжди поруч, допомагала і радила. Усім рекомендую: вона чудовий і грамотний фахівець. Анно, спасибі!"
                                    )}
                                </div>
                            </div>
                            <div className="review-card-2">
                                <div className="review-header">
                                    <div className="review-author">{t("Лана Акімова")}</div>
                                    <div className="review-rating">★★★★★</div>
                                </div>
                                <div className="review-date">{t("15 квітня 2024")}</div>
                                <div className="review-text">
                                    {t(
                                        "Довго самі шукали підходящий варіант квартири в малоповерховому будинку, і неодноразово зверталися в агентство і не прогадали. Ріелтор швидко зорієнтував за наявністю об'єктів і в районі, що цікавить ..."
                                    )}
                                </div>
                            </div>
                            <div className="review-card-3">
                                <div className="review-header">
                                    <div className="review-author">{t("Олена Казакова")}</div>
                                    <div className="review-rating">★★★★★</div>
                                </div>
                                <div className="review-date">{t("16 грудня 2023")}</div>
                                <div className="review-text">
                                    {t(
                                        "Зверталися до філії на Армійській з приводу купівлі комерційного приміщення, менеджерка Ганна швидко зорієнтувала по варіантах і в нашому ціновому сегменті. У підсумку зупинилися на БЦ, що стоїть на Краснова ..."
                                    )}
                                </div>
                            </div>
                            <div className="review-card-4">
                                <div className="review-header">
                                    <div className="review-author">{t("Оленька Ковальска")}</div>
                                    <div className="review-rating">★★★★★</div>
                                </div>
                                <div className="review-date">{t("9 жовтня 2023")}</div>
                                <div className="review-text">
                                    {t(
                                        "Найкрутіше агенство нерухомості! Дякую за ваш ПРОФЕСІОНАЛІЗМ!!! Процвітання вам! Побільше вдячних клієнтів! Побільше угод!"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurReviews;
