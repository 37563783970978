import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { putFavoritesObjects } from "../store/reducers/favorites";
import { getItemWithExpiry, setItemWithExpiry } from "../utils/localsotrage";

const useFavoriteStorage = (key, defaultValue, idElement) => {
  const dispatch = useDispatch();
  const [value, setValueStorage] = useState(() => {
    let currentValue;
    try {
      const currentValueVitTTL = getItemWithExpiry(key, []);
      currentValue = JSON.parse(
        currentValueVitTTL || JSON.stringify(defaultValue),
      );
    } catch (error) {
      currentValue = defaultValue;
    }
    return currentValue;
  });
  const [isPresent, setIsPresent] = useState(() => {
    return value.indexOf(idElement) > -1 ? true : false;
  });

  useEffect(() => {
    setIsPresent(value.indexOf(idElement) > -1 ? true : false);
  }, [value, key]);

  const toggleState = () => {
    let currentValueFromLocalStorage;
    try {
      const currentValueVitTTL = getItemWithExpiry(key, []);
      currentValueFromLocalStorage = JSON.parse(
        currentValueVitTTL || JSON.stringify(defaultValue),
      );
    } catch (error) {
      currentValueFromLocalStorage = defaultValue;
    }
    const index = currentValueFromLocalStorage.indexOf(idElement);
    const mergedData = [...currentValueFromLocalStorage, ...[idElement]];
    const resultUniqueData = mergedData.filter(
      (item, idx) => mergedData.indexOf(item) === idx,
    );
    if (index > -1) {
      resultUniqueData.splice(index, 1);
    }

    const dataToStorage = JSON.stringify(resultUniqueData);
    setItemWithExpiry(key, dataToStorage, 14 * 24 * 60 * 60 * 1000);
    setValueStorage(resultUniqueData);
    dispatch(putFavoritesObjects(resultUniqueData));
  };
  return { value, toggleState, isPresent };
};
export default useFavoriteStorage;
