import React from "react";
import Banner from "/src/media/primary-banner.png";
import Thumbnail from "/src/media/help-item.png";
import { Link } from "react-router-dom";
import APP_CONFIG from "../../config";
import { useTranslation } from "react-i18next";
import HomePageFilter from "../AppHeader/components/HomePageFilter";
import DialogBooking from "../Dialog/DialogBooking";

const AppMobMenuLinks = [
  {
    title: "Придбати",
    route: "/",
  },
  {
    title: "Продати",
    route: "/sell",
  },
  // {
  //   title: "Оцінити",
  //   route: "/",
  // },
  {
    title: "Про компанію",
    route: "/about-us",
  },
  // {
  //   title: "Новини",
  //   route: "/",
  // },
  {
    title: "Контакти",
    route: "/",
  },
];

const PrimaryCarousel = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="primary-carousel--wrap">
      <div className="primary-carousel--item">
        <img src={Banner} alt="Dominanta" />
      </div>

      <div className="primary-carousel-top--layer">
        <div className="container">
          <div className="text-row">
            <div className="cell">
              <h1>
                <span className="dominanta-word">Dominanta</span> — &nbsp;
                {t("безпечний пошук нерухомості в Одесі")}{" "}
              </h1>

              <p>{t("Дванадцять років на одеському ринку нерухомості")} </p>
            </div>

            <div className="cell">
              <div
                className="help-item"
                onClick={() => {
                  window.open(APP_CONFIG.outerLinks.exclusive);
                }}
              >
                <div className="text-layer">
                  <div className="label-text">{t("Допомога експерта")}</div>

                  <h3>{t("Отримайте п'ять пропозицій під ваш запит")}</h3>
                </div>

                <div className="thumbnail-wrap">
                  <img src={Thumbnail} alt={"help"} />
                </div>

              </div>
              <DialogBooking className="home-page--primary-carousel-cell-button" model={{}} textOrder={t("Отримати добірку")} />
            </div>
          </div>

          {/* Mobile menu elements */}
          <div className="mobile-menu-scroll--container">
            <ul className="mobile-menu-scroll">
              {AppMobMenuLinks &&
                AppMobMenuLinks.map((obj, i) => {
                  return (
                    <li
                      className="mobile-menu-scroll--item"
                      key={`${i}_mobile-menu-scroll--item`}
                    >
                      <Link className="mobile-menu-link" to={obj.route} key={i}>
                        {t(obj.title)}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
          {/* End */}

          <HomePageFilter />
        </div>
      </div>
    </div>
  );
};

export default PrimaryCarousel;
