const APP_CONFIG = {
  nodeEnv: process.env.NODE_ENV,
  GOOGLE_MAP_KEY: process.env.RAZZLE_GOOGLE_MAP_KEY,
  apiBaseUrl: process.env.RAZZLE_API_URL,
  phone: {
    hrefPhone: "tel:+380631702585",
    visualPhone: "+38 (063) 170 25 85",
  },
  email: "info@dominanta-d.com",
  social: {
    instagram: "https://www.instagram.com/dominanta.d",
    youTube: "https://www.youtube.com/@user-of6sg4jr5b",
    facebook: "https://www.facebook.com/dominanta.ua",
    telegram: "https://t.me/Odesa_dominanta_realestate",
  },
  outerLinks: {
    exclusive: "https://exclusive.dominanta-d.com",
  },
  breadCrumbs: {
    default: [{ title: "Головна", path: "/" }],
    defaultVtorichka: [
      { title: "Головна", path: "/" },
      { title: "Квартири вторинний ринок", path: "/catalog" },
      // { title: "Квартири вторинний ринок", path: "/catalog/vtorichka" },
    ],
    defaultCommerce: [
      { title: "Головна", path: "/" },
      { title: "Комерція", path: "/commerce" },
    ],
    defaultHouses: [
      { title: "Головна", path: "/" },
      { title: "Будинки", path: "/house" },
    ],
    defaultPlots: [
      { title: "Головна", path: "/" },
      { title: "Ділянки", path: "/plots" },
    ],
    defaultRealtors: [
      { title: "Головна", path: "/" },
      { title: "Ріелтори", path: "/#" },
    ],
    defaultSell: [
      { title: "Головна", path: "/" },
      { title: "Продати", path: "/sell" },
    ],
    defaultAboutUs: [
      { title: "Головна", path: "/" },
      { title: "Про компанію", path: "/about-us" },
    ],
    defaultContacts: [
      { title: "Головна", path: "/" },
      { title: "Контакти", path: "/contacts" },
    ],
  },
};

export default APP_CONFIG;
