import APP_CONFIG from "/src/config.js";
import { useDispatch } from "react-redux";
import { putObjects } from "../store/reducers/objects";

const ApiPort = {
  fetchCount: (query) => {
    const run = async (query) => {
      // const dispatch = useDispatch()
      let d = query;
      return fetch(`${APP_CONFIG.apiBaseUrl}api/count?${query}`)
        .then((response) => response.json())
        .then((data) => {
          return data;
          // dispatch(putObjects(data));
          // data.links,data.meta
          // setPlacesOnTheMap(data.data)
        });
    };
    return run(query).then((r) => r);
  },
};
export default ApiPort;
